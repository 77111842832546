<template>
    <modal :name="modal_name" class="final-modal" transition="nice-modal-fade">
        <div class="v-modal-content">
            <div class="v-modal-header">
                <span class="v-modal-dialog-title v-modal-title">CALCULATION</span>
                <button type="button" class="closeModal" aria-label="Close" @click="hideLaborCost()">
                    <span aria-hidden="true">
                        <i class="icon icon-close"></i>
                    </span>
                </button>
            </div>
            <div class="v-modal-body pt-0 pb-0">
                <div class="v-modal-layout d-form">
                    <div class="m-6 mb-13">
                        <div class="border-radius bg-secondary pt-3 pb-3">
                            <h6 class="font-weight-bold text-white text-center">
                                LABOR COST = <br>
                                COST PER HOUR x NUMBER OF HOURS
                            </h6>
                            <!-- <h6 class="font-weight-bold text-white mt-2 text-center">STOCK COUNT QUANTITY - POS FINAL QUANTITY</h6> -->
                        </div>
                        <div class="d-flex mt-7">
                            <div class="quantity-details bx-shadow">
                                <span class="font-weight-bold d-block">Cost/Hour</span>
                                <span class="text-secondary d-block pt-3">{{labor_details.cost_hour}}</span>
                            </div>
                            <div class="quantity-details pl-2 pr-2">
                                <span class="font-weight-bold d-block">Number of Hours</span>
                                <span class="text-secondary pt-3 d-block">{{labor_details.no_of_hours}}</span>
                            </div>
                        </div>
                        <div class="final-cost d-flex pl-3 pr-3 pt-2 mt-7 justify-content-around">
                            <h6 class="font-weight-bold">LABOR COST</h6>
                            <h6 class="text-secondary font-weight-bold">{{currency.symbol}} {{total_labor_cost}}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
export default {
    props:['modal_name','labor_details','currency'],
    methods:{
        hideLaborCost() {
            this.$modal.hide(this.modal_name);
        }    
    },
    computed:{
        total_labor_cost(){
            return this.labor_details.cost_hour * this.labor_details.no_of_hours
        }
    }
}
</script>
<style scoped>
.border-radius{
    border-radius: 12px;
}
.quantity-details{
    flex: 1 1 250px;
    font-size: 16px;
    text-align: center;
}
.bx-shadow{
    box-shadow: 3px 0 0px -2px #989898;
}
.final-cost{
    border-top: solid 1px #989898;
    border-bottom: solid 1px #989898;
}
</style>